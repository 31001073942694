import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setLanguage } from '../store/languageSlice';
import { Menu, X, User, LogOut } from 'lucide-react';
import { RootState, AppDispatch } from '../store';
import { logout } from '../store/authSlice';
import logo from '../assets/images/logo-navbar.png';

const LogoWithBanner = () => {
  // Calculate width based on original aspect ratio (720:405) and current height (75px)
  // 75 * (720/405) ≈ 133px
  return (
    <div className="relative h-[75px] w-[133px] ml-2.5">
      <img src={logo || ''} alt="HEARTS Logo" className="h-full w-full object-contain" />
      <div className="absolute top-0 right-0 overflow-hidden h-[75px] w-[133px] ml-2.5">
        <div className="absolute top-14 right-[-15px] rotate-45 transform origin-top-right">
          <div className="w-24 text-center py-0.5 bg-red-600 text-white text-[10px] font-bold">
            TESTVERSION
          </div>
        </div>
      </div>
    </div>
  );
};

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const [authLoaded, setAuthLoaded] = useState(false);

  useEffect(() => {
    if (isAuthenticated !== undefined) {
      setAuthLoaded(true);
    }
  }, [isAuthenticated]);
  
  const handleLanguageChange = (lang: string) => {
    dispatch(setLanguage(lang));
    i18n.changeLanguage(lang);
    setIsMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate('/login');
      setIsMenuOpen(false);
      setIsProfileMenuOpen(false);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const NavLink: React.FC<{ 
    to: string; 
    children: React.ReactNode; 
    external?: boolean;
    comingSoon?: boolean;
  }> = ({ to, children, external, comingSoon }) => {
    const isActive = location.pathname === to;
    const baseClasses = "font-bold text-lg md:text-xl relative";
    const activeClasses = isActive ? "text-[#47ABDB]" : "text-white";
    const externalClasses = comingSoon ? "text-gray-400 cursor-default" : "hover:underline";

    if (external) {
      return (
        <span className={`${baseClasses} ${activeClasses} ${externalClasses}`}>
          <a 
            href={to} 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={() => setIsMenuOpen(false)}
          >
            {children}
          </a>
          <span className="text-[#47ABDB] ml-0.5">.</span>
        </span>
      );
    }

    if (comingSoon) {
      return (
        <span className={`${baseClasses} ${activeClasses} ${externalClasses} group`}>
          <span>{children}</span>
          <span className="text-[#47ABDB] ml-0.5">.</span>
          <span className="absolute hidden group-hover:block bg-black bg-opacity-70 text-white text-xs p-1 rounded whitespace-nowrap top-full left-1/2 transform -translate-x-1/2 mt-1">
            Coming Soon
          </span>
        </span>
      );
    }

    return (
      <span className={`${baseClasses} ${activeClasses} hover:underline`}>
        <Link to={to} onClick={() => setIsMenuOpen(false)}>{children}</Link>
        <span className="text-[#47ABDB] ml-0.5">.</span>
      </span>
    );
  };

  const LanguageButtons = () => (
    <div className="flex items-center text-xl gap-4">
      <button
        onClick={() => handleLanguageChange('de')}
        className={`font-bold px-2.5 ${currentLanguage === 'de' ? 'text-[#47ABDB]' : 'text-white'} hover:text-[#47ABDB] transition-colors duration-300`}
      >
        DE
      </button>
      <button
        onClick={() => handleLanguageChange('en')}
        className={`font-bold px-2.5 ${currentLanguage === 'en' ? 'text-[#47ABDB]' : 'text-white'} hover:text-[#47ABDB] transition-colors duration-300`}
      >
        EN
      </button>
    </div>
  );

  const ProfileMenu = () => (
    <div className="relative">
      <button
        onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
        className="flex items-center gap-2 font-bold text-white hover:text-[#47ABDB] transition-colors duration-300"
      >
        <User size={20} />
      </button>
      {isProfileMenuOpen && (
        <div className="lg:absolute lg:right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
          <Link
            to="/profile"
            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
            onClick={() => {
              setIsProfileMenuOpen(false);
              setIsMenuOpen(false);
            }}
          >
            Profile Settings
          </Link>
          <button
            onClick={() => {
              handleLogout();
              setIsProfileMenuOpen(false);
              setIsMenuOpen(false);
            }}
            className="flex items-center w-full px-4 py-2 text-red-600 hover:bg-gray-100"
          >
            <LogOut size={16} className="mr-2" />
            Sign out
          </button>
        </div>
      )}
    </div>
  );

  return (
    <header className="bg-transparent px-4 md:px-6 lg:px-8 py-4">
      <div className="flex justify-between items-center">
        <Link to="/" className="h-[75px] ml-2.5">
          <LogoWithBanner />
        </Link>
        <nav className="flex items-center">
          <div className={`${isMenuOpen ? 'flex flex-col absolute top-[70px] right-0 bg-[#90C4D9] w-full p-4 shadow-md z-50 gap-6' : 'hidden lg:flex items-center gap-4 xl:gap-8'}`}>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/explore">Explore</NavLink>
            <NavLink to="https://expert.heartport.org" external>HeartPort Expert</NavLink>
            <NavLink to="https://conference.heartport.org" external>Hearts Conference</NavLink>
            <NavLink to="/crew">Crew</NavLink>
            
            {/* Mobile-only dividers */}
            <div className="lg:hidden w-full border-t border-white my-2"></div>
            
            {/* Language selection - shown in both mobile and desktop */}
            <div className={`lg:ml-16 lg:border-l lg:border-white lg:pl-16 ${isMenuOpen ? 'flex justify-center' : ''}`}>
              <LanguageButtons />
            </div>
            
            {/* Profile/Login section - shown in both mobile and desktop */}
            <div className={`lg:ml-16 lg:border-l lg:border-white lg:pl-16 ${isMenuOpen ? 'flex justify-center' : ''}`}>
              {isAuthenticated && user ? (
                <ProfileMenu />
              ) : (
                <NavLink to="/login">Sign in</NavLink>
              )}
            </div>
          </div>
          
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="lg:hidden text-white focus:outline-none ml-4"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;