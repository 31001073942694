import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Book } from 'lucide-react';
import axios from '../axios';
import { Hearticle } from '../types';
import lzillner from '../assets/images/lzillner.png';

interface Playlist {
  id: number;
  name: string;
  description: string;
  status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
  hearticles: Hearticle[];
}

const PlaylistCarousel = () => {
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    visibleCards: getInitialVisibleCards()
  });
  const [imageErrors, setImageErrors] = useState<{[key: string]: boolean}>({});

  const navigate = useNavigate();

  function getInitialVisibleCards() {
    const width = window.innerWidth;
    if (width >= 1280) return 5;      // xl
    if (width >= 1024) return 4;      // lg
    if (width >= 768) return 3;       // md
    if (width >= 640) return 2;       // sm
    return 1;                         // xs
  }

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') imageName = 'recover_immunity_&_supplements.png';
    imageName = 'big/' + imageName;
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };
  
  const handleImageError = (topicName: string) => {
    setImageErrors(prev => ({
      ...prev,
      [topicName]: true
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const visibleCards = getInitialVisibleCards();
      setScreenSize({ width, visibleCards });
      
      if (playlists.length > 0) {
        const maxIndex = playlists.length - visibleCards;
        if (currentIndex > maxIndex) {
          setCurrentIndex(Math.max(0, maxIndex));
        }
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentIndex, playlists.length]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await axios.get<Playlist[]>('/api/playlist/public');
        if (!Array.isArray(response.data)) {
          setPlaylists([]);
          return;
        }
  
        // First process all playlists through the map and filter
        const processedPlaylists = response.data
          .map(playlist => {            
            return playlist;
          })
          .filter(playlist => 
            playlist.hearticles && 
            playlist.hearticles.length > 0
          );
  
        const sortedPlaylists = processedPlaylists.sort((a, b) => b.id - a.id);
  
        console.log("playlists: ", sortedPlaylists);
        setPlaylists(sortedPlaylists);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        setPlaylists([]);
      }
    };
    fetchPlaylists();
  }, []);

  const handleNext = () => {
    if (playlists.length === 0) return;
    const maxIndex = playlists.length - screenSize.visibleCards;
    setCurrentIndex((prevIndex) => 
      prevIndex >= maxIndex ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    if (playlists.length === 0) return;
    const maxIndex = playlists.length - screenSize.visibleCards;
    setCurrentIndex((prevIndex) => 
      prevIndex <= 0 ? maxIndex : prevIndex - 1
    );
  };

  const getVisiblePlaylists = () => {
    if (playlists.length === 0) return [];
    return playlists.slice(currentIndex, currentIndex + screenSize.visibleCards);
  };

  const getPlaylistColor = (playlist: Playlist): string => {
    const defaultColor = '#49ABDB66';
    if (!playlist?.hearticles?.[0]?.topic?.color) {
      return defaultColor;
    }
    const color = playlist.hearticles[0].topic.color;
    const isValidHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);
    return isValidHex ? `${color}66` : defaultColor;
  };

  const getHeadline = (hearticle: Hearticle): string => {
    return hearticle.translations?.[0]?.headline || `Hearticle ${hearticle.id}`;
  };

  if (!playlists.length) {
    return (
      <div className="text-center py-8 text-gray-500">
        No playlists available
      </div>
    );
  }

  return (
    <div className="relative mt-16 mb-16">
      <h2 className="text-4xl font-bold mb-8 text-[#49ABDB] md:p-8">Guided HEARTPORTS</h2>
      
      <div className="relative mx-16">
        <div className="flex justify-center md:justify-between items-center gap-4">
          {getVisiblePlaylists().map((playlist) => (
            <div
              key={playlist.id}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                navigate(`/playlist/${playlist.id}`);
              }}
              style={{
                width: `calc((100% - ${(screenSize.visibleCards - 1) * 16}px) / ${screenSize.visibleCards})`,
                height: screenSize.width >= 768 ? '550px' : '90vh',
                backgroundColor: getPlaylistColor(playlist),
                backdropFilter: 'blur(8px)'
              }}
              className="rounded-lg flex flex-col p-4 cursor-pointer transition-all duration-300 hover:scale-105"
            >
              <div className="mb-4">
                <h3 className="text-lg font-bold mb-2 text-[#5B5B5B] line-clamp-2">
                  {playlist.name || 'Untitled Playlist'}
                </h3>
                <p className="text-[#5B5B5B]/80 text-sm line-clamp-3">
                  {playlist.description || 'No description available'}
                </p>
              </div>

              <div className="flex justify-center items-center my-4 flex-grow">
                {playlist.hearticles?.[0]?.topic?.name ? (
                  imageErrors[playlist.hearticles[0].topic.name] ? (
                    <Book 
                      size={screenSize.width >= 768 ? 160 : 80} 
                      className="text-[#5B5B5B]/60" 
                    />
                  ) : (
                    <img
                      src={getImageUrl(playlist.hearticles[0].topic.name)}
                      onError={() => handleImageError(playlist.hearticles[0].topic.name)}
                      alt={`${playlist.hearticles[0].topic.name} Topic Image`}
                      className="md:w-40 md:h-40 w-20 h-20 object-contain"
                    />
                  )
                ) : (
                  <Book 
                    size={screenSize.width >= 768 ? 160 : 80} 
                    className="text-[#5B5B5B]/60" 
                  />
                )}
              </div>
              
              <div className="space-y-2 mt-auto">
                {(playlist.hearticles || []).slice(0, 2).map((hearticle) => (
                  <div 
                    key={hearticle.id}
                    className="bg-white/10 rounded p-2 text-[#5B5B5B] text-sm line-clamp-2"
                  >
                    {getHeadline(hearticle)}
                  </div>
                ))}
                {(playlist.hearticles?.length ?? 0) > 2 && (
                  <div className="flex items-center gap-2 text-[#5B5B5B]/80 text-sm">
                    <Book size={16} />
                    <span>+{playlist.hearticles.length - 2} more articles</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {playlists.length > screenSize.visibleCards && (
          <>
            <button
              onClick={handlePrev}
              className="absolute -left-16 top-1/2 -translate-y-1/2"
            >
              <ChevronLeft className="w-12 h-12 text-[#49ABDB] stroke-[3]" />
            </button>
            
            <button
              onClick={handleNext}
              className="absolute -right-16 top-1/2 -translate-y-1/2"
            >
              <ChevronRight className="w-12 h-12 text-[#49ABDB] stroke-[3]" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PlaylistCarousel;