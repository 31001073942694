import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { Hearticle, HearticleTranslation } from '../types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import ArticleCard from './ArticleCard';
import SpecialArticleCard from './SpecialArticleCard';

interface RecommendationsComponentProps {
  personalizedCount: number;
  generalCount: number;
  headline?: string;
  subheadline?: string;
  showReadCount?: boolean;
  topicId?: number;
}

const RecommendationsComponent: React.FC<RecommendationsComponentProps> = ({
  personalizedCount,
  generalCount,
  headline = '',
  subheadline = '',
  showReadCount = false,
  topicId
}) => {
  const { t } = useTranslation();
  const [recommendations, setRecommendations] = useState<Hearticle[]>([]);
  const [specialArticle, setSpecialArticle] = useState<Hearticle | null>(null);
  const [readCount, setReadCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const user = useSelector((state: RootState) => state.auth.user);

  const getTranslation = (hearticle: Hearticle): HearticleTranslation => {
    const translation = hearticle.translations.find(t => t.languageCode === currentLanguage);
    return translation || hearticle.translations[0];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        let recommendationsUrl: string;
        let params: Record<string, any> = {};

        if (topicId) {
          const totalLimit = personalizedCount + generalCount;
          
          if (isAuthenticated) {
            recommendationsUrl = `/api/hearticles/topic/${topicId}/most-read/not-interacted`;
            params = {
              userId: user?.id,
              limit: totalLimit
            };
          } else {
            recommendationsUrl = `/api/hearticles/topic/${topicId}/most-read`;
            params = { limit: totalLimit };
          }
        } else {
          recommendationsUrl = '/api/hearticles/recommendations';
          params = {
            personalizedCount,
            generalCount
          };
        }

        // Fetch recommendations first to determine if we need special article
        const recsResponse = await axios.get(recommendationsUrl, { params });
        const fetchedRecommendations = recsResponse.data;

        // Prepare other promises based on recommendations length
        const promises = [
          Promise.resolve(fetchedRecommendations),
          showReadCount && isAuthenticated
            ? axios.get('/api/interactions/user/read-count')
            : Promise.resolve({ data: 0 }),
          fetchedRecommendations.length <= 3
            ? axios.get('/api/public/hearticle/303')
            : Promise.resolve({ data: null })
        ];

        const [recommendations, readCountRes, specialRes] = await Promise.all(promises);

        setRecommendations(recommendations);

        if (showReadCount && isAuthenticated) {
          setReadCount(readCountRes.data);
        }

        if (fetchedRecommendations.length <= 3 && specialRes.data && specialRes.data.id) {
          setSpecialArticle(specialRes.data);
        }

      } catch (err) {
        console.error('Failed to fetch recommendations:', err);
        setError(t('errors.failedToFetchRecommendations'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    personalizedCount,
    generalCount,
    topicId,
    isAuthenticated,
    user?.id,
    currentLanguage,
    showReadCount,
    t
  ]);

  if (loading) {
    return <p className="text-center">{t('loading')}</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="mx-0 lg:mx-4 my-32 bg-white">
      {subheadline && (
        <p className="text-2xl font-bold text-[#111D4E] p-4">{subheadline}</p>
      )}
      {headline && (
        <h2 className="text-4xl font-bold mb-6 text-[#47ABDB] p-4">
          {headline}
        </h2>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        {recommendations.map(article => (
          <ArticleCard
            key={article.id}
            article={article}
            translation={getTranslation(article)}
            imageSize="md"
          />
        ))}

        {recommendations.length <= 3 &&
          specialArticle &&
          specialArticle.translations &&
          user?.roles?.includes('PERMISSION_ADMIN') && (
            <SpecialArticleCard
              hearticle={specialArticle}
              translation={getTranslation(specialArticle)}
            />
          )}
      </div>

      {showReadCount && isAuthenticated && (
        <div className="text-center mt-12 pb-20">
          <p className="text-[#111D4E] text-xl font-bold mb-4">
            {t('readProgress.subtitle')}
          </p>
          <h3 className="text-[#47ABDB] text-3xl font-bold mb-4">
            {t('readProgress.title')}
          </h3>
          <p className="text-[#47ABDB] text-4xl font-bold">{readCount}</p>
          <p className="text-[#47ABDB] text-3xl">Hearticles</p>
        </div>
      )}
    </div>
  );
};

export default RecommendationsComponent;