// Publication type quality mappings
const QUALITY_LEVELS = {
    HIGH: {
      color: '#2ae8ec',
      types: [
        'Meta-Analysis',
        'Systematic Review',
        'Meta-Analysis, Systematic Review'
      ]
    },
    MEDIUM: {
      color: '#ccfff5',
      types: [
        'Randomized Controlled Trial',
        'Clinical Trial',
        'Controlled Clinical Trial',
        'Comparative Study',
        'Cohort Studies',
        'Observational Study',
        'Multicenter Study'
      ]
    },
    LOW: {
      color: '#ffb4a2',
      types: [
        'Case Reports',
        'Case-Control Studies',
        'Editorial',
        'Expert Opinion',
        'Review',
        'Comment',
        'Letter',
        'Practice Guideline'
      ]
    }
  };
  
  export const getStudyQualityColor = (publicationTypes: string[]): string => {
    // Default color if no match is found
    const DEFAULT_COLOR = 'transparent';
    
    // If no publication types, return default
    if (!publicationTypes?.length) return DEFAULT_COLOR;
    
    // Check for high quality match first
    const hasHighQuality = publicationTypes.some(type => 
      QUALITY_LEVELS.HIGH.types.includes(type)
    );
    if (hasHighQuality) return QUALITY_LEVELS.HIGH.color;
    
    // Then check for medium quality
    const hasMediumQuality = publicationTypes.some(type => 
      QUALITY_LEVELS.MEDIUM.types.includes(type)
    );
    if (hasMediumQuality) return QUALITY_LEVELS.MEDIUM.color;
    
    // Finally check for low quality
    const hasLowQuality = publicationTypes.some(type => 
      QUALITY_LEVELS.LOW.types.includes(type)
    );
    if (hasLowQuality) return QUALITY_LEVELS.LOW.color;
    
    // If no matches found, return default color
    return DEFAULT_COLOR;
  };