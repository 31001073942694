import React, { useEffect, useState } from 'react';
import axios from '../axios';

type ReviewerHearticleCountProjection = {
  reviewerId: number;
  reviewerUsername: string;
  hearticleCount: number;
};

type TopicReviewCountDto = {
  topicId: number;
  topicName: string;
  reviewCount: number;
};

type TopicUnreviewedHearticleCountDto = {
  topicId: number;
  topicName: string;
  unreviewedHearticleCount: number;
};

const ReviewStatistics: React.FC = () => {
  const [reviewerHearticleCounts, setReviewerHearticleCounts] = useState<ReviewerHearticleCountProjection[]>([]);
  const [topicReviewCounts, setTopicReviewCounts] = useState<TopicReviewCountDto[]>([]);
  const [unreviewedOverall, setUnreviewedOverall] = useState<number>(0);
  const [unreviewedByTopic, setUnreviewedByTopic] = useState<TopicUnreviewedHearticleCountDto[]>([]);

  useEffect(() => {
    fetchDistinctHearticleCountByReviewer();
    fetchReviewCountByTopic();
    fetchUnreviewedOverall();
    fetchUnreviewedByTopic();
  }, []);

  const fetchDistinctHearticleCountByReviewer = async () => {
    try {
      const response = await axios.get<ReviewerHearticleCountProjection[]>('/api/review/distinct-hearticles-per-reviewer');
      setReviewerHearticleCounts(response.data);
    } catch (error) {
      console.error('Error fetching distinct hearticles per reviewer:', error);
    }
  };

  const fetchReviewCountByTopic = async () => {
    try {
      const response = await axios.get<TopicReviewCountDto[]>('/api/review/reviews-per-topic');
      setTopicReviewCounts(response.data);
    } catch (error) {
      console.error('Error fetching review count by topic:', error);
    }
  };

  const fetchUnreviewedOverall = async () => {
    try {
      const response = await axios.get<number>('/api/review/unreviewed-hearticles/overall');
      setUnreviewedOverall(response.data);
    } catch (error) {
      console.error('Error fetching unreviewed hearticles overall:', error);
    }
  };

  const fetchUnreviewedByTopic = async () => {
    try {
      const response = await axios.get<TopicUnreviewedHearticleCountDto[]>('/api/review/unreviewed-hearticles/by-topic');
      setUnreviewedByTopic(response.data);
    } catch (error) {
      console.error('Error fetching unreviewed hearticles by topic:', error);
    }
  };

  const getTotalReviewedHearticles = () => {
    return reviewerHearticleCounts.reduce((sum, reviewer) => sum + reviewer.hearticleCount, 0);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Hearticle Review Statistics</h2>

      {/* Summary Statistics */}
      <div className="grid grid-cols-2 gap-4 mb-8 p-4 bg-gray-50 rounded-lg">
        <div className="text-center p-4 bg-white rounded shadow">
          <h3 className="text-lg font-semibold text-gray-600 mb-2">Unreviewed Hearticles</h3>
          <p className="text-3xl font-bold text-blue-600">{unreviewedOverall}</p>
        </div>
        <div className="text-center p-4 bg-white rounded shadow">
          <h3 className="text-lg font-semibold text-gray-600 mb-2">Total Reviewed Hearticles</h3>
          <p className="text-3xl font-bold text-green-600">{getTotalReviewedHearticles()}</p>
        </div>
      </div>

      {/* Existing tables */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Distinct Hearticles per Reviewer</h3>
        <table className="min-w-full bg-white text-black border">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 text-left">Username</th>
              <th className="py-2 px-4 text-left">Hearticle Count</th>
            </tr>
          </thead>
          <tbody>
            {reviewerHearticleCounts.map((item) => (
              <tr key={item.reviewerId} className="border-b">
                <td className="py-2 px-4">{item.reviewerUsername}</td>
                <td className="py-2 px-4">{item.hearticleCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Reviews per Topic</h3>
        <table className="min-w-full bg-white text-black border">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 text-left">Topic Name</th>
              <th className="py-2 px-4 text-left">Review Count</th>
            </tr>
          </thead>
          <tbody>
            {topicReviewCounts.map((item) => (
              <tr key={item.topicId} className="border-b">
                <td className="py-2 px-4">{item.topicName}</td>
                <td className="py-2 px-4">{item.reviewCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Unreviewed Hearticles by Topic</h3>
        <table className="min-w-full bg-white text-black border">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 text-left">Topic Name</th>
              <th className="py-2 px-4 text-left">Unreviewed Count</th>
            </tr>
          </thead>
          <tbody>
            {unreviewedByTopic.map((item) => (
              <tr key={item.topicId} className="border-b">
                <td className="py-2 px-4">{item.topicName}</td>
                <td className="py-2 px-4">{item.unreviewedHearticleCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReviewStatistics;