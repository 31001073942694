import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Hearticle, PubMedArticle, InteractionDTO, InteractionType, SharePlatform, HearticleTranslation } from '../types';
import axios from '../axios';
import logo from '../assets/images/logo_with_text.png';
import atom from '../assets/images/atom.png';
import header from '../assets/videos/header.mp4';
import lzillner from '../assets/images/lzillner.png';

import RelatedStudies from './RelatedStudies';
import ShareButtons from './ShareButtons';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { Button } from '../components/Button';
import DemocratisingScience from './DemocratisingScience';
import RecommendationsComponent from './RecommendationsComponent';
import { useTranslation } from 'react-i18next';
import PlayButton from './PlayButton';
import DeleteHearticleButton from './DeleteHearticleButton';
import ReviewBadge from './ReviewBadge';
import RegenerateHearticleButton from './RegenerateHearticleButton';
import { useCanTrackInteractions } from '../hooks/cookieConsent';
import { Book } from 'lucide-react';
import { getFeaturedTopicForDate } from '../utils/featuredTopicUtils';
import { fetchDailyReadCount } from '../store/readCountSlice';

interface Reviewer {
  id: number;
  username: string;
  email: string;
}

interface Review {
  id: number;
  reviewer: Reviewer;
  status: string;
}

const PaywallMessage = () => (
  <div className="min-h-screen bg-white p-8">
    <div className="max-w-2xl mx-auto text-center">
      <h2 className="text-3xl font-bold text-[#49ABDB] mb-6">Daily Limit Reached</h2>
      <p className="text-xl text-gray-700 mb-8">
        You've reached your daily limit of free reads. Upgrade to continue reading.
      </p>
      <button 
        onClick={() => window.location.href = '/upgrade'}
        className="bg-[#49ABDB] text-white px-8 py-3 rounded-lg font-semibold hover:bg-[#3d8fb8] transition-colors"
      >
        Upgrade Now
      </button>
    </div>
  </div>
);

const FullHearticlePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const canTrackInteractions = useSelector(useCanTrackInteractions());
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [hearticle, setHearticle] = useState<Hearticle | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [readStartTime, setReadStartTime] = useState<number>(Date.now());
  const [translationReviews, setTranslationReviews] = useState<{ [translationId: number]: Review[] }>({});
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const relatedStudiesRef = useRef<HTMLDivElement>(null);
  const [activeStudyId, setActiveStudyId] = useState<string | null>(null);
  const [videoError, setVideoError] = useState(false);
  const headlineRef = useRef<HTMLHeadingElement>(null);
  const [imageError, setImageError] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);

  const dailyReadCount = useSelector((state: RootState) => state.readCount.dailyReadCount);
  console.log('monthly read count: ', dailyReadCount);
  const user = useSelector((state: RootState) => state.auth.user);
  const isPaid = user?.roles?.includes('PERMISSION_PAID_ACCESS') || 
                 user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') || 
                 false;
  const hasReachedLimit = false//!isPaid && dailyReadCount >= 30 && !isFeatured;
  
  
  useEffect(() => {
    const checkIfFeatured = async () => {
      const featuredTopic = await getFeaturedTopicForDate(new Date());
      if (featuredTopic && hearticle) {
        setIsFeatured(hearticle.topic.id === featuredTopic.id);
      }
    };
    
    if (hearticle) {
      checkIfFeatured();
    }
  }, [hearticle]);

  useEffect(() => {
    if (videoError) {
      console.log('Featured video failed to load');
    }
  }, [videoError]);

  useEffect(() => {
    if (!loading && hearticle && headlineRef.current) {
      headlineRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [loading, hearticle]);

  const scrollToStudy = (pmid: string) => {
    setActiveStudyId(pmid);
  };

  const hasReviewPermission = useSelector((state: RootState) => 
    state.auth.user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') ?? false
  );

  const hasPaidPermission = useSelector((state: RootState) => 
    state.auth.user?.roles?.includes('PERMISSION_PAID_ACCESS') ?? false
  );

  const hasAdminPermission = useSelector((state: RootState) => 
    state.auth.user?.roles?.includes('PERMISSION_ADMIN') ?? false
  );
  
  const getCurrentTranslation = useMemo((): HearticleTranslation | null => {
    if (!hearticle) return null;
    const translation = hearticle.translations.find(t => t.languageCode === currentLanguage);
    return translation || hearticle.translations[0];
  }, [hearticle, currentLanguage]);

  useEffect(() => {
    const fetchHearticle = async () => {
      try {
        const response = await axios.get(`/api/public/hearticle/${id}`);
        console.log("hearticle: ", response.data)
        setHearticle(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch hearticle');
        setLoading(false);
      }
    };

    fetchHearticle();
  }, [id]);

  const recordReadInteraction = async () => {
    if (!canTrackInteractions) return;

    try {
      const interaction: InteractionDTO = {
        hearticleId: Number(id),
        interactionType: InteractionType.READ,
      };
      await axios.post('/api/interactions', interaction);
    } catch (error) {
      console.error('Failed to record read interaction:', error);
    }
  };

  useEffect(() => {
    const fetchReviews = async () => {
      if (getCurrentTranslation) {
        try {
          const response = await axios.get<Review[]>(`/api/review/translation/${getCurrentTranslation.id}`);
          setTranslationReviews(prev => ({
            ...prev,
            [getCurrentTranslation.id]: response.data
          }));
          console.log("reviews: ", response)
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
      }
    };

    fetchReviews();
  }, [getCurrentTranslation]);

  useEffect(() => {
    if (id) {
      //recordReadInteraction();
      setReadStartTime(Date.now());
    }
  
    
    if (id && canTrackInteractions) {        
      const interaction: InteractionDTO = {
        hearticleId: Number(id),
        interactionType: InteractionType.READ,
      };

      axios.post('/api/interactions', interaction).catch(error => {
        console.error('Failed to record time spent:', error);
      }).then(() => {
        // Now fetch the updated read count
        dispatch(fetchDailyReadCount());
      });
    }
    
  }, [id, canTrackInteractions]);




  const handleRelatedArticleClick = async (pmid: string) => {
    if (!canTrackInteractions || !id) return;

    if (id) {
      try {
        const interaction: InteractionDTO = {
          hearticleId: Number(id),
          interactionType: InteractionType.VIEW_RELATED
        };
        await axios.post('/api/interactions', interaction);
      } catch (error) {
        console.error('Failed to record related article interaction:', error);
      }
    }
  };

  const EnhancedShareButtons: React.FC<{ hearticleId: string }> = ({ hearticleId }) => {
    const handleShare = async (platform: string) => {
      if (canTrackInteractions) {
        try {
          const interaction: InteractionDTO = {
            hearticleId: Number(hearticleId),
            interactionType: InteractionType.SHARE,
            sharePlatform: platform as SharePlatform,
          };
          await axios.post('/api/interactions', interaction);
        } catch (error) {
          console.error('Failed to record share interaction:', error);
        }
      }

      const url = `${window.location.origin}/hearticle/${hearticleId}`;

      if (platform === 'native' && navigator.share) {
        try {
          await navigator.share({
            title: 'Check out this interesting Hearticle!',
            url: url
          });
          return;
        } catch (error) {
          console.error('Failed to use native share:', error);
          // Fall through to platform-specific sharing if native share fails
        }
      }

    switch (platform) {
      case 'instagram':
        try {
          await navigator.clipboard.writeText(url);
          alert('Link copied! To share on Instagram:\n\n1. Open Instagram\n2. Create a new Story\n3. Paste the link using the "Link" sticker');
        } catch (error) {
          console.error('Failed to copy link:', error);
          alert('Failed to copy link. Please try again.');
        }
        break;
        
      case 'linkedin':
        window.open(`https://www.linkedin.com/feed/share?url=${encodeURIComponent(url)}&title=${encodeURIComponent('Check out this interesting Hearticle!')}`, '_blank');
        break;
        
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent('Check out this interesting Hearticle!')}`, '_blank');
        break;
        
      case 'email':
        window.open(`mailto:?subject=${encodeURIComponent('Check out this interesting Hearticle!')}&body=${encodeURIComponent(url)}`, '_blank');
        break;
    }
  };

    return <ShareButtons hearticleId={hearticleId} onShare={handleShare} />;
  };

  const refMap = useMemo(() => {
    if (!hearticle || !getCurrentTranslation) return {};
    let refCounter = 1;
    const map: { [key: string]: number } = {};
    const translation = getCurrentTranslation;
    const allText = translation.mainTextBody + ' ' + 
                   translation.mainActions.join(' ') + ' ' + 
                   translation.harmfulActions.join(' ');
    const refRegex = /\[Ref(\d+)(?:,\s*Ref(\d+))*\]/g;
    let match;
    while ((match = refRegex.exec(allText)) !== null) {
      match.slice(1).filter(Boolean).forEach(pmid => {
        if (!map[pmid]) {
          map[pmid] = refCounter++;
        }
      });
    }
    return map;
  }, [hearticle, getCurrentTranslation]);

  

  if (loading) {
    return <div>Loading...</div>;
  }

  // Early return with error state
  if (error || !hearticle || !getCurrentTranslation) {
    return <div>Error: {error || t('hearticle.notFound')}</div>;
  }

  if (hasReachedLimit) {
    return <PaywallMessage />;
  }

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') imageName = 'recover_immunity_&_supplements.png';
    imageName = 'big/' + imageName;
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };
  
  const handleImageError = () => {
    setImageError(true);
  };

  
  const renderFormattedText = (text: string) => {
    const lines = text.split('\n');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return lines.map((line, lineIndex) => {
      const elements: React.ReactNode[] = [];
      let lastIndex = 0;
      const pattern = /(\*\*(.*?)\*\*)|(\*(.*?)\*)|(__(.*?)__)|(_(.*?)_)|(\[Ref\d+(?:,\s*Ref\d+)*\])|(\{(.*?)\}\s*)\[EXP:(.*?)\]/g;
      let match;
  
      while ((match = pattern.exec(line)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
          elements.push(line.substring(lastIndex, match.index));
        }
  
        // Process the match
        if (match[9]) { // [Ref...] case
          const refPart = match[9];
          const refs = refPart
            .slice(1, -1)
            .split(',')
            .map((ref) => ref.trim().slice(3));
          elements.push(
            <sup key={`${lineIndex}-${match.index}-ref`}>
              {refs.map((pmid, index) => {
                const article = hearticle?.relatedPubMedArticles.find(
                  (a) => a.pmid === pmid
                );
                return (
                  <React.Fragment key={index}>
                    {index > 0 && ','}
                    <span
                      className="text-blue-500 cursor-pointer text-xs align-super ml-0.5"
                      title={article ? article.title : 'Article not found'}
                      onClick={(e) => {
                        e.preventDefault();
                        if (article?.pmid) {
                          if (isMobile) {
                            window.open(`https://pubmed.ncbi.nlm.nih.gov/${article.pmid}`, '_blank');
                          } else {
                            scrollToStudy(article.pmid);
                          }
                        }
                      }}
                    >
                      [{refMap[pmid]}]
                    </span>
                  </React.Fragment>
                );
              })}
            </sup>
          );
        } else if (match[1]) {
          // **bold**
          elements.push(
            <strong key={`${lineIndex}-${match.index}-bold`}>
              {match[2]}
            </strong>
          );
        } else if (match[3]) {
          // *italic*
          elements.push(
            <em key={`${lineIndex}-${match.index}-italic`}>
              {match[4]}
            </em>
          );
        } else if (match[5]) {
          // __underline__
          elements.push(
            <u key={`${lineIndex}-${match.index}-underline`}>
              {match[6]}
            </u>
          );
        } else if (match[7]) {
          // _italic_
          elements.push(
            <em key={`${lineIndex}-${match.index}-italic-underscore`}>
              {match[8]}
            </em>
          );
        } else if (match[10]) {
          // {words}[EXP:...]
          const words = match[11];
          const explanation = match[12];
          elements.push(
            <span
              key={`${lineIndex}-${match.index}-exp`}
              className="text-blue-500 cursor-pointer relative group"
              onClick={(e) => {
                const tooltip = e.currentTarget.querySelector('.tooltip');
                if (tooltip) {
                  tooltip.classList.toggle('visible');
                }
              }}
            >
              {words}
              <span className="tooltip invisible group-hover:visible absolute bottom-full left-1/2 transform -translate-x-1/2 px-3 py-1.5 bg-black text-white text-sm mb-2 z-50 max-w-[280px] whitespace-normal break-words md:max-w-[24rem] lg:break-normal lg:w-max">
                {explanation.trim()}
                <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></span>
              </span>
            </span>
          );
        
        }
  
        lastIndex = pattern.lastIndex;
      }
  
      // Add any remaining text after the last match
      if (lastIndex < line.length) {
        elements.push(line.substring(lastIndex));
      }
  
      return (
        <React.Fragment key={lineIndex}>
          {elements}
          {lineIndex < lines.length - 1 && <br />}
        </React.Fragment>
      );
    });
  };

  if (hasReachedLimit) {
    return (
      <div className="p-8 text-black">
        <h2 className="text-2xl mb-4">Daily Limit Reached</h2>
        <p>You've used your 10 free reads for today. Upgrade to read more!</p>
      </div>
    );
  }
  
  const translation = getCurrentTranslation as HearticleTranslation;


  return (
    <div>
      <div className="mx-auto md:p-4 mx-0 lg:mx-4 font-sans bg-white">
        {/* Header */}
        <header className="flex justify-between p-4 lg:p-0 items-center lg:mb-4">
          <div className="flex items-center">
            <img src={logo || ''} alt="HeartPort logo" className="mr-2 w-auto h-14 md:w-auto md:h-auto" />
          </div>
        </header>

        {/* Main Title and Subtext */}
        <h1 className="text-3xl lg:text-8xl font-bold mx-4 mb-4 lg:mt-10 lg:mx-0 pt-4 text-[#49ABDB]">
          DEMOCRATIZING SCIENCE
        </h1>
        <div className="absolute right-0 md:right-10 md:top-72 transform lg:w-[150px] lg:h-[150px] w-[100px] h-[100px] -translate-y-[50%] hidden md:block">
      <video 
        src={header} 
        autoPlay 
        loop 
        muted 
        playsInline 
        className="w-full h-full rounded-full"
        style={{
          objectFit: 'cover', // Ensures the content fits inside the circular frame
        }}
      />
    </div>
        <p className="pb-6 lg:pb-12 text-sm lg:text-xl m-4 lg:m-0 text-[#5B5B5B] font-bold italic">
          {t('heartport.subtitle')}
        </p>

        {/* Image */}
        <div className="w-full flex justify-center relative z-10">
        {imageError ? (
          <div className="w-72 h-72 xl:w-[36rem] xl:h-[36rem] flex items-center justify-center">
            <Book 
              size={window.innerWidth >= 1280 ? 288 : 144} 
              className="text-gray-600"
            />
          </div>
        ) : (
          <img
            src={getImageUrl(hearticle.topic.name)}
            onError={handleImageError}
            alt="Topic Image"
            className="w-72 h-72 xl:w-[36rem] xl:h-[36rem] object-contain"
          />
        )}
      </div>

        {/* div overlapping image */}
        <div className="bg-[#d0e6ef] p-4 relative mt-[-9rem] md:mt-[-18rem] z-0 sm:mt-4">
          <div className="absolute top-4 left-4 md:mx-6 md:pt-0 pt-36">
            <DemocratisingScience />
          </div>
          {/* Spacer to adjust for the image */}
          <div className="h-72 md:h-64"></div>

          {/* Three columns */}
          <div className="flex flex-col md:flex-row md:mx-6">
            <div className="w-full md:w-1/3 mb-4 md:mb-0 mt-4 sm:mt-6">
              <h4 className="text-xl md:text-3xl font-bold text-black mb-2 text-[#111D4E]">YOUR BREAKING HEARTICLE:</h4>
              <div
                className={`px-2 py-1 rounded inline-block text-black mb-2`}
                style={{ backgroundColor: hearticle.topic.color}}
              >
                {hearticle.topic.name}
              </div>
              <h5 ref={headlineRef} className="text-4xl md:text-4xl font-bold text-[#49ABDB] mb-2 break-words">
                {translation?.headline}
              </h5>
                
              <p className="mb-2 text-2xl font-semibold text-black italic">
                {translation?.keywords?.join(' - ') || t('noKeywordsAvailable')}
              </p>
              
              {/* Now we use the pre-computed permission */}
              {hasReviewPermission && (
                <div className="flex items-center mt-4">
                  <Button
                    onClick={() => navigate(`/review/translation/${hearticle.id}`)}
                    variant="primary"
                  >
                    {t('review.reviewTranslation')}
                  </Button>
                  {hasAdminPermission && (
                  <RegenerateHearticleButton hearticleId={hearticle.id} />
                )}
                  {/*<DeleteHearticleButton 
                    hearticleId={hearticle.id} 
                    headline={translation.headline} 
                  />*/}
                </div>
              )}
            </div>

            {/* Middle third */}
            <div className="w-full md:w-1/3 flex flex-col items-center justify-center mb-4 md:mb-0 md:mt-16 md:gap-8">
          <PlayButton 
            hearticleId={hearticle.id} 
            translationId={translation.id}
          />
          <div className="mt-4">
            <ReviewBadge 
              review={translationReviews[translation.id]
                ?.filter(r => r.status === 'APPROVED')
                .sort((a, b) => b.id - a.id)[0] ?? null}
              t={t}
            />
          </div>
        </div>

            {/* Right third */}
<div className="w-full md:w-1/3 flex flex-col items-center md:mt-16">
  {/*<div className="mb-4 rounded-lg overflow-hidden">
    <video
      className="h-48 w-full md:h-72 md:w-auto [&::-webkit-media-controls-current-time-display]:text-white [&::-webkit-media-controls-time-remaining-display]:text-white"
      style={{
        objectFit: 'cover',
        WebkitAppearance: 'none'
      }}
      playsInline
      controls
      preload="metadata"
      src={`${process.env.REACT_APP_API_URL}/uploads/featured/${id}.mp4`}
      onError={() => setVideoError(true)}
    />
  </div>*/}
  <p className="mb-1 md:mb-2 text-sm md:text-lg text-white text-center">
                Your Insights matter - read, share, democratize!
              </p>
  <p className="mb-2 text-2xl md:text-3xl text-white font-bold">SHARE HEARTICLE</p>
  <EnhancedShareButtons hearticleId={id || ''} />
</div>
          </div>

          {/* HEALTH ESSENTIALS heading */}
          <h3 className="text-2xl font-bold text-[#49ABDB] mb-2 mt-8 md:mx-6">HEALTH ESSENTIALS</h3>

          {/* Main body and action feed */}
          <div className="flex flex-col md:flex-row">
            {/* Main body */}
            <div className="w-full md:w-2/3 pr-0 md:pr-4 mb-4 md:mb-0 md:mx-6">
              <p className="text-l text-justify text-black mb-4 whitespace-pre-wrap">
                {renderFormattedText(translation.mainTextBody)}
              </p>
            </div>

            {/* Action feed */}
            <div className="w-full md:w-1/3 md:mx-4">
              <div className="bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] p-4">
                <div className="mb-4">
                  <h3 className="text-4xl font-bold mb-6 mt-4 text-white">{t('heartport.actionFeed')}</h3>
                  <br />
                  
                  {/* Main Actions - only show if there are items */}
                  {translation.mainActions.length > 0 && (
                    <>
                      <h4 className="text-2xl font-bold mb-6 text-white italic">{t('heartport.helpful')}</h4>
                      <ul className="list-disc list-inside text-white text-xl">
                        {translation.mainActions.map((action, index) => (
                          <li key={index} className="whitespace-pre-wrap mb-4 font-semibold">
                            {renderFormattedText(action)}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {/* Centered Atom Image - only show if either list has items */}
                  {(translation.mainActions.length > 0 || translation.harmfulActions.length > 0) && (
                    <div className="flex justify-center my-8">
                      <img src={atom} alt="Atom" className="w-16 h-16" />
                    </div>
                  )}

                  {/* Harmful Actions - only show if there are items */}
                  {translation.harmfulActions.length > 0 && (
                    <>
                      <h4 className="text-2xl font-bold mb-6 text-white italic">{t('heartport.harmful')}</h4>
                      <ul className="list-disc list-inside text-white text-xl">
                        {translation.harmfulActions.map((action, index) => (
                          <li key={index} className="whitespace-pre-wrap mb-4 font-semibold">
                            {renderFormattedText(action)}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Related Studies at the end */}
          <div ref={relatedStudiesRef}>
            <RelatedStudies 
              studies={hearticle.relatedPubMedArticles} 
              activeStudyId={activeStudyId}
              onActiveStudyChange={setActiveStudyId}
            />
          </div>

          
        </div>
      </div>
      <div className="w-full">
            {/* Mission Statement Section */}
            <div className="bg-[#90C4D9] py-16 px-4 mt-12">
              <div className="container mx-auto max-w-4xl text-center">
                <h2 className="text-3xl md:text-4xl font-bold text-white">
                  {t('hearticle.missionStatement', 'We fight disease with the power of scientifically reviewed health essentials')}
                </h2>
              </div>
            </div>

            {/* Additional Share Buttons Section */}
            <div className="py-12">
              <div className="container mx-auto px-4">
                <div className="flex flex-col items-center">
                <div className="w-full md:w-1/3 flex flex-col items-center">
                  <p className="mb-2 text-2xl md:text-3xl text-white font-bold">SHARE HEARTICLE</p>
                  <EnhancedShareButtons hearticleId={id || ''} />
                </div>
                </div>
              </div>
            </div>
          </div>
      
        {hearticle && hearticle.topic && (
          <RecommendationsComponent
            personalizedCount={4}
            generalCount={0}
            topicId={hearticle.topic.id}
            headline=""
            subheadline=""
          />
        )}
      <RecommendationsComponent
        personalizedCount={0}
        generalCount={4}
        headline={t("recommendations.baseHeadline")}
        subheadline={t("recommendations.baseSubheadline")}
      />

      {isAuthenticated && (
              <RecommendationsComponent
                personalizedCount={4}
                generalCount={0}
                headline={t("recommendations.persHeadline")}
                subheadline={t("recommendations.persSubheadline")}
              />
      )}
    </div>
  );
};

export default FullHearticlePage;