import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PubMedArticle, PubMedArticleTranslation } from '../types';
import { getStudyQualityColor } from '../utils/study-quality';
import { RootState } from '../store';
import studyImage from '../assets/images/study.png';
import studyQualityDE from '../assets/images/study_quality_de.png';
import studyQualityEN from '../assets/images/study_quality_en.png';

import header from '../assets/videos/header.mp4';

import { Button } from './Button';

interface RelatedStudiesProps {
  studies: PubMedArticle[];
  activeStudyId: string | null;
  onActiveStudyChange: (pmid: string | null) => void;
}

const RelatedStudies: React.FC<RelatedStudiesProps> = ({ 
    studies, 
    activeStudyId, 
    onActiveStudyChange 
  }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  
  const studyRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const safeStudies = Array.isArray(studies) ? studies.filter(Boolean) : [];
  const displayedStudies = expanded ? safeStudies : safeStudies.slice(0, Math.min(3, safeStudies.length));
  const showViewMoreButton = !expanded && safeStudies.length > 3;
  
  useEffect(() => {
    if (activeStudyId) {
      const studyIndex = safeStudies.findIndex(s => s.pmid === activeStudyId);
      
      if (studyIndex >= 3 && !expanded) {
        setExpanded(true);
      }
  
      const scrollTimer = setTimeout(() => {
        const studyElement = studyRefs.current[activeStudyId];
        if (studyElement) {
          studyElement.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 300);
  
      return () => clearTimeout(scrollTimer);
    }
  }, [activeStudyId, safeStudies, expanded]);  

  const getTranslation = (study: PubMedArticle): PubMedArticleTranslation | undefined => {
    if (!study?.translations?.length) return undefined;
    const translation = study.translations.find(t => t?.languageCode === currentLanguage);
    return translation || study.translations[0];
  };

  const getContent = (study: PubMedArticle) => {
    const translation = getTranslation(study);
    const abstract = translation?.translatedAbstract || study?.abstractText || t('common.abstractNotAvailable');
    
    return {
      title: translation?.translatedTitle || study?.title || t('common.titleNotAvailable'),
      abstract: abstract.split(/\n+/).filter(Boolean),
    };
  };

  const renderAbstractParagraphs = (abstract: string[]) => {
    return abstract.map((paragraph, index) => (
      <p key={index} className="text-sm leading-relaxed mb-4 last:mb-0">
        {paragraph}
      </p>
    ));
  };

  const rows = displayedStudies.reduce((acc: PubMedArticle[][], study, index) => {
    const rowIndex = Math.floor(index / 4);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(study);
    return acc;
  }, []);

  const handleViewMore = () => setExpanded(true);
  const handleStudyClick = (pmid: string) => {
      onActiveStudyChange(activeStudyId === pmid ? null : pmid);
  };
  const getAuthors = (study: PubMedArticle) => {
    return study?.authors?.filter(Boolean)?.join(', ') || t('common.authorsNotAvailable');
  };
  const getPublicationType = (study: PubMedArticle) => {
    return study?.publicationTypes?.filter(Boolean)?.join(', ') || t('common.publicationTypeNotAvailable');
  };
  const getJournal = (study: PubMedArticle) => {
    return study?.journal || t('common.journalNotAvailable');
  };

  if (!safeStudies.length) return null;

  const renderStudyExpandedMobile = (study: PubMedArticle, isRightColumn = false) => {
    const content = getContent(study);
    return (
      <div className="relative" ref={el => studyRefs.current[study.pmid] = el}>

        <div 
          className={`w-[calc(100vw-1rem)] bg-[#CDE1EE] p-6 mt-0 mb-6 ${
            isRightColumn ? '-ml-[calc(100%+1.5rem)]' : '-ml-2'
          }`} 
          style={{ color: '#111D4E' }}
        >
          <h4 className="text-xl font-bold mb-4 text-[#49ABDB]">
            {content.title}
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h5 className="font-semibold mb-2">{t('studies.abstractLabel')}</h5>
              <div className="space-y-4">
                {renderAbstractParagraphs(content.abstract)}
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <h5 className="font-semibold mb-2">{t('studies.authorsLabel')}</h5>
                <p className="text-sm">{getAuthors(study)}</p>
              </div>
              <br></br>
              <div>
                <h5 className="font-semibold mb-2">{t('studies.studyType')}</h5>
                <p className="text-sm line-clamp-3">{getPublicationType(study)}</p>
              </div>
              <br></br>
              <div>
                <h5 className="font-semibold mb-2">{t('studies.journalLabel')}</h5>
                <p className="text-sm line-clamp-3">{getJournal(study)}</p>
              </div>
              <br></br>
              <div>
                <p className="text-sm">{t('studies.autogenerated')}</p>
              </div>
            </div>
          </div>
          {study.url && (
            <div className="mt-6">
              <a 
                href={study.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary">
                  {t('studies.viewOnPubMedButton')}
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderStudyExpandedDesktop = (study: PubMedArticle) => {
    const content = getContent(study);
    return (
      <div ref={el => studyRefs.current[study.pmid] = el}
        className="w-full bg-[#CDE1EE] p-6 mt-0 mb-6 overflow-hidden" 
        style={{ color: '#111D4E' }}
      >
        <h4 className="text-xl font-bold mb-4 text-[#49ABDB] line-clamp-2">
          {content.title}
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h5 className="font-semibold mb-2">{t('studies.abstractLabel')}</h5>
            <div className="space-y-4">
              {renderAbstractParagraphs(content.abstract)}
            </div>
          </div>
          <div className="space-y-4">
            <div>
              <h5 className="font-semibold mb-2">{t('studies.authorsLabel')}</h5>
              <p className="text-sm line-clamp-3">{getAuthors(study)}</p>
            </div>
            <br></br>
            <div>
              <h5 className="font-semibold mb-2">{t('studies.studyType')}</h5>
              <p className="text-sm line-clamp-3">{getPublicationType(study)}</p>
            </div>
            <br></br>
            <div>
              <h5 className="font-semibold mb-2">{t('studies.journalLabel')}</h5>
              <p className="text-sm line-clamp-3">{getJournal(study)}</p>
            </div>
            <br></br>
            <div>
              <p className="text-sm">{t('studies.autogenerated')}</p>
            </div>
          </div>
        </div>
        {study.url && (
          <div className="mt-6">
            <a 
              href={study.url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block"
            >
              <Button variant="primary">
                {t('studies.viewOnPubMedButton')}
              </Button>
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mt-4 md:mt-8 md:mx-6 max-w-full overflow-hidden">
      <h3 className="text-xl md:text-4xl font-bold mb-4 md:mb-6 px-4 md:px-0 line-clamp-2" style={{ color: '#49ABDB' }}>
        {t('studies.viewReferencesTitle')}
      </h3>
      
      {/* Mobile Layout */}
      <div className="md:hidden px-4">
        <div className="flex flex-wrap -mx-2">
          {displayedStudies.map((study, index) => (
            <React.Fragment key={study?.pmid || `study-${index}`}>
              <div className="w-1/2 px-2 mb-4">
                <button 
                  onClick={() => study?.pmid && handleStudyClick(study.pmid)}
                  className="w-full text-left focus:outline-none relative"
                >
                  <div className="flex flex-col items-center relative">
                    {activeStudyId === study?.pmid && (
                      <div className="absolute inset-0 bg-[#CDE1EE] z-0" style={{
                        bottom: '-2rem',
                      }} />
                    )}
                      <div 
                          className="relative w-full pb-[100%] overflow-hidden transition-all duration-200 z-10"
                      >
                      <div className="absolute inset-0 flex items-center justify-center">
  <div 
    className="relative w-4/5 h-4/5"
    style={{
      borderRadius: '50%',
      border: `2px solid ${getStudyQualityColor(study?.publicationTypes || [])}`,
      overflow: 'hidden',
    }}
  >
    <img
      src={studyImage || ""}
      alt={t('studies.studyImageAlt')}
      className="absolute inset-0 w-full h-full object-contain bg-transparent"
      style={{
        borderRadius: '50%',
        transform: 'scale(1.06)', // Using the same scale factor that worked well for desktop
      }}
    />
  </div>
</div>
                    </div>
                    <p className="text-xs text-center h-20 overflow-hidden relative z-10 line-clamp-4 px-2" 
                       style={{ color: '#111D4E' }}>
                      {getContent(study).title}
                    </p>
                  </div>
                </button>
                {activeStudyId === study?.pmid && renderStudyExpandedMobile(study, index % 2 === 1)}
              </div>
            </React.Fragment>
          ))}
          {showViewMoreButton && (
            <div className="w-full px-2 mb-4">
              <Button 
                onClick={handleViewMore}
                variant="primary"
                className="w-full h-28 text-lg font-bold"
              >
                {t('studies.viewMoreButton')}
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Desktop Layout */}
      <div className="hidden md:block">
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="mb-4 relative">
            <div className="flex flex-wrap -mx-4">
              {row.map((study) => (
                <div key={study?.pmid || `study-${rowIndex}`} className="w-1/4 px-4 mb-6">
                  <button 
                    onClick={() => study?.pmid && handleStudyClick(study.pmid)}
                    className="w-full text-left focus:outline-none relative"
                  >
                    <div className="flex flex-col items-center relative">
                      {activeStudyId === study?.pmid && (
                        <div className="absolute inset-0 bg-[#CDE1EE] z-0" style={{ bottom: '-2rem' }} />
                      )}
                      <div 
  className="relative w-4/5 pb-[80%] overflow-hidden transition-all duration-200 z-10"
  onMouseEnter={(e) => e.currentTarget.querySelector('video')?.play()}
  onMouseLeave={(e) => {
    const video = e.currentTarget.querySelector('video');
    if (activeStudyId !== study?.pmid) video?.pause();
  }}
>
  <div className="absolute inset-0 flex items-center justify-center">
    <div 
      className="relative w-4/5 h-4/5"
      style={{
        borderRadius: '50%',
        border: `12px solid ${getStudyQualityColor(study?.publicationTypes || [])}`,
        overflow: 'hidden',
      }}
    >
      <video
        src={header} 
        muted 
        loop 
        playsInline
        className="absolute inset-0 w-full h-full object-contain bg-transparent"
        style={{
          borderRadius: '50%',
          transform: 'scale(1.06)', // Slightly scale up the video to overlap the border
        }}
        ref={(videoElement) => {
          if (videoElement && activeStudyId !== study?.pmid) {
            videoElement.pause();
          }
        }}
      />
    </div>
  </div>
</div>
                      <p 
                        className="text-sm text-center h-16 overflow-hidden relative z-10 line-clamp-3 px-2" 
                        style={{ color: '#111D4E' }}
                      >
                        {getContent(study).title}
                      </p>
                    </div>
                  </button>
                </div>
              ))}
              {rowIndex === 0 && showViewMoreButton && (
                <div className="w-1/4 px-4 mb-6">
                  <div className="flex flex-col h-full">
                    <div className="flex-grow mb-4">
                      <img
                        src={currentLanguage === 'de' ? studyQualityDE : studyQualityEN}
                        alt={t('studies.studyQualityImageAlt')}
                        className="w-full h-auto object-contain"
                      />
                    </div>
                    <Button 
                      onClick={handleViewMore}
                      variant="primary"
                      className="w-full text-lg font-bold py-2"
                    >
                      {t('studies.viewMoreButton')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {row.some(study => study?.pmid === activeStudyId) && (
              <div className="w-full">
                {row.map(study => study?.pmid === activeStudyId && renderStudyExpandedDesktop(study))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedStudies;