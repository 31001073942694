import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import { RootState } from './index' // or wherever you export RootState

// 1. Define the type for our slice state
interface ReadCountState {
  dailyReadCount: number
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

// 2. Initial state
const initialState: ReadCountState = {
  dailyReadCount: 0,
  status: 'idle',
  error: null,
}

// 3. Thunk to fetch daily read count from your backend
export const fetchDailyReadCount = createAsyncThunk<number>(
  'readCount/fetchDailyReadCount',
  async (_, { rejectWithValue }) => {
    try {
      // Your backend endpoint for daily read count
      const response = await axios.get('/api/interactions/monthly-read-count')
      return response.data as number
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch daily read count')
    }
  }
)

// 4. Slice
export const readCountSlice = createSlice({
  name: 'readCount',
  initialState,
  reducers: {
    // Optionally, a manual way to set daily read count if you want
    setDailyReadCount: (state, action) => {
      state.dailyReadCount = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyReadCount.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchDailyReadCount.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.dailyReadCount = action.payload
      })
      .addCase(fetchDailyReadCount.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export const { setDailyReadCount } = readCountSlice.actions

export default readCountSlice.reducer
