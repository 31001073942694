// SpecialArticleCard.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PlayButton from './PlayButton';
import access_video from '../assets/videos/access_science.mp4';
import { Hearticle, HearticleTranslation } from '../types';

interface SpecialArticleCardProps {
  hearticle: Hearticle;
  translation: HearticleTranslation;
  className?: string;
}

const SpecialArticleCard: React.FC<SpecialArticleCardProps> = ({
  hearticle,
  translation,
  className = '',
}) => {
  const { t } = useTranslation();

  return (
    <div className={`bg-[#3ea2d6] p-4 relative min-h-[250px] sm:min-h-[280px] flex flex-col border-4 border-black ${className}`}>
      <div className="absolute top-3 right-3 w-16 h-16 sm:w-20 sm:h-20 overflow-hidden rounded">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-contain"
        >
          <source src={access_video} type="video/mp4" />
        </video>
      </div>

      <div className="pr-20 space-y-3 flex-1">
        <div className="px-2 py-1 inline-block rounded text-xs sm:text-sm line-clamp-1 bg-black text-white">
          {hearticle.topic.name}
        </div>
        
        <h3 className="text-base sm:text-lg xl:text-xl font-bold text-black line-clamp-2">
          {translation?.headline || t('noHeadlineAvailable')}
        </h3>
        
        <p className="text-xs sm:text-sm xl:text-base text-black line-clamp-2 italic">
          {translation?.keywords?.join(' - ') || t('noKeywordsAvailable')}
        </p>
      </div>
      
      <div className="flex items-center gap-2">
        <Link
          to={`/hearticle/303`}
          className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-black hover:opacity-90 transition-opacity"
        >
          {t('heartport.readFullHearticle')}
        </Link>
        <div className="mt-2">
          
        </div>
      </div>
    </div>
  );
};

export default SpecialArticleCard;