import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { getFeaturedTopicForDate } from '../utils/featuredTopicUtils';
import play from '../assets/images/play.png';
import pause from '../assets/images/pause.png';

interface PlayButtonProps {
  hearticleId: number;
  translationId: number;
}

const PlayButton: React.FC<PlayButtonProps> = ({ hearticleId, translationId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioUrl = `${process.env.REACT_APP_API_URL}/uploads/${hearticleId}/${translationId}.mp3`;
  
  const dailyReadCount = useSelector((state: RootState) => state.readCount.dailyReadCount);
  const user = useSelector((state: RootState) => state.auth.user);
  const isPaid = user?.roles?.includes('PERMISSION_PAID_ACCESS') || user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') || false;
  
  useEffect(() => {
    const checkIfFeatured = async () => {
      const featuredTopic = await getFeaturedTopicForDate(new Date());
      const article = await fetch(`/api/public/hearticle/${hearticleId}`).then(res => res.json());
      setIsFeatured(article.topic.id === featuredTopic?.id);
    };
    checkIfFeatured();
  }, [hearticleId]);

  const hasReachedLimit = false//!isPaid && dailyReadCount >= 30 && !isFeatured;

  const handleClick = () => {
    if (hasReachedLimit) {
      alert("You've reached your daily free-read limit! Upgrade to continue.");
      return;
    }
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div>
      <button
        onClick={handleClick}
        disabled={hasReachedLimit}
        className={`flex items-center justify-center w-12 h-12 rounded-full ${
          hasReachedLimit ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90 transition-opacity'
        }`}
      >
        <img
          src={isPlaying ? pause : play}
          alt={isPlaying ? 'Pause' : 'Play'}
          className="w-full h-full"
        />
      </button>
      <audio
        ref={audioRef}
        src={audioUrl}
        onEnded={() => setIsPlaying(false)}
        className="hidden"
      />
    </div>
  );
};
export default PlayButton;
