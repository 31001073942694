import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getCurrentUser, restoreToken } from './store/authSlice';
import { LanguageDetector } from './utils/languageDetection';
import { setLanguage } from './store/languageSlice';
import HeartPort from './components/HeartPort';
import FullHearticlePage from './components/FullHearticlePage';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Signup from './components/Signup';
import ReviewPage from './components/ReviewPage';
import Profile from './components/Profile';
import { AppDispatch, RootState } from './store';
import ResetPassword from './components/PasswordResetComponent';
import Crew from './components/Crew';
import CookieBanner from './components/CookieBanner';
import flower from './assets/images/flower_1.png';
import { useTranslation } from 'react-i18next';
import { fetchDailyReadCount } from './store/readCountSlice';
import PaymentSuccess from './components/PaymentSuccess';

const AppContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [authLoading, setAuthLoading] = useState(true);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { i18n } = useTranslation();

  useEffect(() => {
    // Initialize language detection
    const languageDetector = new LanguageDetector({
      defaultLanguage: 'de',
      supportedLanguages: ['de', 'en']
    });

    const detectedLanguage = languageDetector.detectLanguage();
    dispatch(setLanguage(detectedLanguage));
    i18n.changeLanguage(detectedLanguage);
    languageDetector.saveLanguagePreference(detectedLanguage);
    
    // Initialize auth
    dispatch(restoreToken());
    setAuthLoading(false);
  }, [dispatch, i18n]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
      dispatch(fetchDailyReadCount());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div className="min-h-screen text-white bg-[#CAD4DD]">
      <Header />
      <Routes>
        <Route path="/" element={<HeartPort />} />
        <Route path="/playlist/:playlistId" element={<HeartPort />} />
        <Route path="/playlist/unpublished/:playlistId" element={<HeartPort />} />
        <Route path="/hearticle/:id" element={<FullHearticlePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/review/translation/:id" element={<ReviewPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/crew" element={<Crew />} />
        <Route path="/explore" element={<Navigate to="/?mode=explore" replace />} />
      </Routes>
      <CookieBanner flowerImagePath={flower} />
      <Footer />
    </div>
  );
};

export default AppContent;