// PastHearticlesComponent.tsx
import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { Hearticle, HearticleTranslation } from '../types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const PastHearticlesComponent: React.FC = () => {
  const [hearticles, setHearticles] = useState<Hearticle[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

  const getTranslation = (hearticle: Hearticle): HearticleTranslation => {
    const translation = hearticle.translations.find(t => t.languageCode === currentLanguage);
    return translation || hearticle.translations[0];
  };

  useEffect(() => {
    const fetchHearticles = async () => {
      try {
        const response = await axios.get('/api/public/hearticles/all-past', {
          params: {
            page,
            size: 8,
          },
        });
        setHearticles(response.data.content);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch past hearticles:', error);
        setError(t('errors.failedToFetchHearticles'));
        setLoading(false);
      }
    };

    fetchHearticles();
  }, [page, t]);

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.src = `${process.env.REACT_APP_API_URL}/uploads/topic_images/nutrition_medicine.png`;
    target.onerror = null;
  };

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') imageName = 'recover_immunity_&_supplements.png';
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };

  if (loading) {
    return <p className="text-center">{t('loading')}</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="mx-0 lg:mx-4 my-8 bg-white">
      <h2 className="text-4xl font-bold mb-6 text-[#47ABDB] p-4">Past Hearticles</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        {hearticles.map((article) => {
          const translation = getTranslation(article);
          if (article.translations[0].headline==="Zugang zur Wissenschaft - Lernen Sie die Grundlagen der Wissenschaft, um Forschung zu verstehen, zu bewerten und zwischen verschiedenen Studientypen wie Meta-Analysen, Reviews und anderen zu unterscheiden.")
          {
            return (<></>)
          }
          if (article.topic.name==="Science Essentials")
            {
              return (<></>)
            }
          return (
            <div
              key={article.id}
              className="bg-[#CAD4DD] p-4 rounded relative min-h-[200px] xl:h-[280px]"
            >
              <img
                src={getImageUrl(article.topic.name)}
                alt={article.topic.name}
                onError={handleImageError}
                className="absolute top-2 right-2 w-16 h-16 xl:w-24 xl:h-24 object-contain"
              />
              <div className="pr-20">
                <div
                  className="px-2 py-1 rounded inline-block my-2 text-xs xl:text-base"
                  style={{
                    backgroundColor: article.topic.color,
                    color: '#000000',
                  }}
                >
                  {article.topic.name}
                </div>
                <h3 className="text-base xl:text-xl font-bold text-[#49ABDB] line-clamp-2">
                  {translation?.headline || t('noHeadlineAvailable')}
                </h3>
                <p className="text-xs xl:text-base text-black line-clamp-2 italic">
                  {translation?.keywords?.join(' - ') || t('noKeywordsAvailable')}
                </p>
                <Link
                  to={`/hearticle/${article.id}`}
                  className="inline-block px-3 py-1 mt-2 text-sm xl:text-base text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                >
                  {t('heartport.readFullHearticle')}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      
      {/* Pagination Controls */}
      <div className="flex justify-center items-center gap-4 mt-6 p-4">
        <button
          onClick={() => setPage(prev => Math.max(0, prev - 1))}
          disabled={page === 0}
          className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
        >
          {t('heartport.previous')}
          </button>
        <span className="text-[#111D4E]">
          {t('heartport.pageOf', { current: page + 1, total: totalPages })}
        </span>
        <button
          onClick={() => setPage(prev => Math.min(totalPages - 1, prev + 1))}
          disabled={page === totalPages - 1}
          className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
        >
          {t('heartport.next')}
          </button>
      </div>
    </div>
  );
};

export { PastHearticlesComponent };